@media screen and (max-width: 479px) {
  

.container{
    max-width: 400px;
    margin: auto;
  }
  
  .menu-button-container {
      display: flex;
    }
  .hero-nav{
    display:none;
  }
  .article-hero .image-title{
    position: static;
    margin-left: 30px;
  }
  .image-title {
    margin-right: 30px;
    margin-top: -11rem;
  }
  .image-title img{
    height:100%;
    width: 100%;
    object-fit: scale-down;
  }
  .blog-content{
    width: 90%;
  }
  .image-title .title{
    position: static; 
    color: white;
    font-size: 16px;
    width: 100%;  
  }
  .image-title .title-div{
    width: 93.7%;
    border-radius: 20px  20px 0 0;
    position: relative;
    top: 6rem;
    background-color: #F11A7B;
    padding: 10px;
  }
  .featured-blog img[alt="featured-blog"]{
    height: 200px;
    width: 98%;
    object-fit: cover;
    border-radius: 20px;
  }
  /* .featured-blog .blog-type{
    font-size: 16px;
  } */
  
  .top-reviews h1{
    font-size: 30px;
  }
  .blog-card .author-info .flex{
    margin: 0;
    margin-top: -1rem;
  }
  
  .blog-hero-card {
    width: 95%;
  }
  .blogs-grid .blog-card img[alt="blog-img"]{
    width: 100%;
    height: 100%;
  }
  .blogs-grid .blog-card p{
    font-size: 14px;
  }
  
  .navigator{
    display: none;
  }
  .blogs-grid .blog-card h3{
    font-size: 16px;
    width: 90%;
  }
  .featured-blog .blog-title, .blogs-grid .blog-card .blog-title{
    font-size: 22px;
    width: 98%;
  }
  .articles .bar{
    height: 200px;
  
  }
  
  .star-name h2{
    margin-bottom: -10px;
  }
  .articles .latest{
    margin-left: 1rem;
  }
  .blogs-grid,.blog-hero-inner{
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .article-info{
    margin-top: -3rem;
    margin-left: 12px;
  }
  .article-hero{
    height: 300px;
    margin-bottom: 0;
  }
  .image-title .author-info p,.image-title .less-line p,.blog-hero-card p {
    font-size: 16px;
  }
  .image-title .author-info img{
    height: 40px;
    width: 40px;
  }
  
    #menu-toggle ~ .menu li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
      border: 1px solid #333;
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .nav-items .button-div,
    .nav-items ul
    {
      display: none;
    }
  
    .nav-items{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }
    .nav-items>* {
      margin-right: 1.5rem;
  }
  .nav-items input::placeholder{
    font-size: 11px;
  }
    .nav-items input{
      background-color: white;
      min-width: 0px; 
      max-width: 100%;
      width: 120px;
  }
  

  .nav-items img[alt='mobile-logo']{
    height: 30px;
    margin-top: 7px;
  }
    .large{
      font-size: 36px !important;
    }
    .header-para{
      font-size: 16px;
      max-width: 100%;
      min-width: 0;
      padding: 0 10px;
    }
    .header-search::placeholder {
      font-size:12px;
      background-size: 9px 9px;
    }
    .popular-apps{
      padding-left: 2.5rem;
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      justify-content: center;
    }
     .popular-apps > :last-child {
    grid-column: 1 / -1;
    justify-self: center;
    text-align: center;
    padding-right: 2.5rem;
  }
    .heading{
      font-size: 18px;
    }
    .home-image {
      height: 200px;
      min-width: 0;
      max-width: 100%;
  }
  
  .home-para{
    font-size: 16px;
    max-width: 70%;
  }
  .blog-list p{ 
    font-size: 16px;
  }
  .blog-list h2{
    font-size: 20px;
  }
  .blog-list{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    padding: 8px ;
  }
  .blog-list div{
    margin-top: 1rem;
  }
  .home-flex p{
    font-size: 14px;
  }
  .reviews-card b{
    font-size: 20px;
}
  .footer-inner{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2,1fr);
   
  }
  
  footer{
    height: 100%;
  }
  
  footer p, footer a{
    font-size: 16px;
  }
  footer h2{
    font-size: 20px;
  }
  .home-before-footer{
    height: 100%;
  }
  
  .before-footer-image{
    height: 200px;
    min-width: 0;
    max-width: 100%;
  }
  
  .home-before-footer h1{
    font-size: 24px;
  }
  
  .home-before-footer p{
    font-size: 16px;
  }
  
  .home-before-footer img[alt="tick"]{
    height: 10px;
  }
  
  
  .home-before-footer div>*{
    margin:5px;
  }
  .home-before-footer .btn{
    font-size: 10px;
  }
  .home-before-footer .ticks{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .tick{
    margin: 10px 1rem 10px 0;
}
  .home-flex{
    margin-top: 2rem;
    margin-bottom: 9rem;
  }
  .home-flex>*{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  }
  .home-flex .image{
    display: none;
  }
  
  .home-flex img[alt="flex-image"]{
    height: 100px;
  }
  
  .home-flex img[alt="tick"]{
    height: 10px;
  }
  .bar img{
    /* display: none; */
    position: relative;
      left: 0.1rem;
      top: -10.5rem;
    height: 150px;

  }
  
  .bar h1{
    position: relative;
      left: 9rem;
      top: 0;
      width: 50%;
    font-size: 1rem;
  }
  
  .bar p{
    font-size: 12px;
    width: 90%;
  }
  
  .bar .btn{
    font-size: 14px;
  }
  .bar{
    margin-top: 5rem;
    position: relative;
    top: 0rem;
    height: 400px;
  }
  
  .bar div{
    display: flex;
    justify-content: center;
    padding: 1rem 1px;
  }
  
  .bar div span{
    width: 90%;
  }
  
  .footer-div{
    margin-left: 0;
  }
  
  .reviews-card{
    margin-left: 0;
  }
  
  .review{
    padding: 1rem;
  }
  
  .footer-first{
    margin-top: -15px;
  }
  
  .hero-inner{
    flex-direction: column;
    text-align: center;
    padding: 1rem 0;
  }
  
  .hero-inner h2{
    width: 100%;
    font-size: 2rem;
  }
  
  .hero-inner h1{
    font-size: 4rem;
  }
  
  .hero-inner img{
    height: 200px;
  }
  
  .question-answer{
    padding: 1rem;
  }
  
  .bread-crumb{
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-left: 1rem;
  }
  
  .reaction-card{
    display: none;
  }
  
  .product-card-top{
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .product-page-card{
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  
  
  .product-page-card>*{
    width: 95%;
  }
  .profile-name-email{
    width: 80%;
    font-size: 14px;
  }
  .profile-password{
    width: 85%;
    font-size: 14px;
    position: relative;
    left: 3rem;
  }
  .dashboard-page-card{
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    max-width: 90%;
  }
  .nav-container{
    width: 90%;
    margin: auto;
  }
  
  #reaction-selector{
    display: block;
    border: none;
    color: #454545;
    /* position: relative;
    top: -10px;
    right: -30px; */
  }
  
  .sidebar-2{
    height: 100vh;
  }
  .rating-comment,
  .subscription-details{
    font-size: 14px !important;
  }

  .rating-comment .stars svg{
    height: 20px;
    width: 20px;
  }
  .rating-comment.flex{
    justify-content: space-between;
  }
  .rating-comment .rating{
    margin-right: 0;
  }
  .product-card-right {
    margin-left: 0;
}
  
  .product-card{
    margin:2rem 10px;
  }
  .unsubscribe-edit{
    position: static;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .subscription-details{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  /* .subscription-details > .flex:last-child {
    grid-column: 1 / -1;
    justify-self: center;
    text-align: center;
  } */

  .product-item-saved{
    margin-left: 0;
    margin-right: 0;
  }
  .rating-comment>* ,
  .subscription-details>*{
    margin-right: 1rem;
  }
  .rating p, .comments img{
    margin-right: 5px;
  }
  
  nav{
    background-color: #F11A7B;
  }
  
  .menu-button,
    .menu-button::before,
    .menu-button::after {
      background-color: white;
    }
    .menu {
      display: block;
      position: absolute;
      top: 0;
      margin-top: 5rem;
      left: 5px;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      
    }
  
  img[alt="logo"], .desktop{
    display: none;
  }
  .mobile-globe-save{
    display: block;
    display: flex;
    align-items: flex-start;
  }
  .mobile-globe-save>*{
    margin-right: 10px;
    height: 20px;
  }
  
  .product-page-image{
    height: 135px;
    margin-top: 10px;
    max-width: 100%;
    min-width: 0;
  }
  .product-page-ques-ans{
    display: none;
  }
  
  .product-page-reaction-card{
    display: block;
  
  }
  .reaction-card{
    background: white;
    
  }
  .reactions {
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  
  .similar-products-list {
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }
  .reaction{
    border: 1px solid #757575;
    margin: 10px;
  }
  
  .similar-products-list>*{
    margin: 0;
  }
  
  .similar-products-list{
    max-width: 400px;
  }
  .side-bar{
    display: none;
  }
  
  .filter-item,
  .sort-by{
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
  }
  .sort-by{
    height: 100%;
    width: 30%;
    max-width: 20%;
    min-width: 0;
}
.sort-by p{
 margin: 0;
}


  .selected-filter{
    color: white;
  background: #F11A7B;
  }
  .reaction-selector,
  .filter-item span {
    display: none;
  }
  .sort-by img{
    height: 15px;
  }
  .filter-item img{
    height: 10px;
  }
  .mobile-none{
    display: none;
  }
  
  .desktop-none{
    display: block;
    border: none;
  }
  .card-heading{
    font-size: 1rem;
    width: 80%;
  }
  
  .dashboard-page-card img[alt='star']{
    height: 15px;
  }
  
  .dashboard-page-card .description{
    font-size: 16px;
  }
  .dashboard-page-card .rating-comment,.f-12{
    font-size: 12px;
  }
  .f-12{
    display: flex;
  }
  .dashboard-main{
    width: 99%;
    margin: auto;
  }
  .dashboard-main h2{
    margin-left: 1rem;
  }
  .filters,
  .sort-select{
    width: 90%;
    margin: 1rem auto;
  }
  .sort-select select{
    margin-bottom: 0;
  }
  .sort-select  #dropdown {
    margin-bottom: 0;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    font-size: 12px;
    margin-left: 10px;
    width: 70%;
    max-width: 70%;
    min-width: 0;
  }
  .sort-select option{
    border: none;
  }
  .sort-select{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .desktop-none .card-heading{
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .overlay{
    overflow: auto;
  }
  
  .overlay-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0;
    background: #FFF;
    box-shadow:none;
    color: #454545;
  }
  .forget-pass {
    margin-left: 14rem;
  }
  .login-component-top{
    margin-top: 1rem;
  }

  .ml-40{
    margin-left: 0;
 }



  .login-register-form .ml-30{
    margin-left: 0;
  }
  .login-register-form .social{
    flex-direction: row;
    margin-top: 1rem;
  }
  .login-component{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow: auto;
  }
  .hurry-up-component{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow: auto;
    align-items: center;
  }



  .hurry-up-item svg{
    margin-right: 10px;
  }
  
  .login-register-form div{
    flex-direction: column;
    align-items: flex-start;
  }
  
  .login-component,
  .hurry-up-component {
    max-width: 90%;
    min-width: 0;
  }
  .login-register-form{
    align-self: flex-start;
    width: 100%;
  }
  .login-register-form input{
    width: 100%;
  margin-top: 10px;
  border-radius: 7px;
  border: 1px solid #ECECEC;
  }

  .login-component .button-div{
    margin: 1.5rem 0;
}
  .radio{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
  .login-register-form input[type='radio']{
   margin-bottom: 15px;
  }
  .hurry-up{
    display: grid;
    grid-template-columns: 1fr;
  }
  .card-open-components select{
    display: none;
  }
  
  .card-open-components .card-open-comments{
    display: none;
  }
  .card-open-comment .dark-text-heading{
    margin-bottom: -15px;
  }


  .card-open-components .dark-text{
    margin-left: 10px;
  }
  .card-open-reactions{
    margin-top: 1.5rem;
  }
  .card-open-components .ratings,
  .card-ratings-product-page .ratings{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  .card-open-comment{
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
  }
  .card-open-components .rating{
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
  }

  .expand-collapse{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 10px 15px;
    background: whitesmoke;
    height: 10px;
    margin-top: 1rem;
    border-radius: 5px;
  }
  
.card-open-components .flex.rating{
  width: 91%;
  margin: auto;
  /* display: block !important; */
}

.mob-no-flex{
  display: block !important;
}
  .card-open-components .ratings p{
    /* margin-right: 4rem; */
    color: #454545;
    font-size: 12px;
  }
  .card-open-components .comment-input,
  .card-comment-product-page .comment-input{
    flex-direction: column;
    margin-left: 12px;
  }
  .card-comment-product-page .btn{
    margin-top: 10px;
  }
  .card-open-components .comment-input .btn{
   width: 90%;
   text-align: center;
   margin-right: 1rem;
  }
  .card-open-components .comment-input>*{
    margin-bottom: 10px;
  }
  .card-open-form select{
    display: block;
  }
  .card-open-form {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
    /* width: 100%; */
    color: #757575;
    /* margin-left: 20px; */
    padding: 20px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 90%;
    margin: auto;
  }
  .card-open-form .btn{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .card-open-form input, .card-open-form select{
    border: none;
    color: #757575;
    font-size: 12px;
  }
  /* .card-open-form select,.card-open-form input[type='date']{
     width: 140px;
  } */

  .card-open-form select,
  .card-open-form input[type='date']{
    font-size: 14px;
    color: #757575;
    font-family:  'Manjari', sans-serif;
  }


  .card-open-form label{
    font-size: 14px;
  }
  .card-open-form input[type='text']{
    width: 90px;
    border: 1px solid rgb(117, 117, 117, 0.5) !important;
    border-radius: 5px;
  }
  .mobile-dashboard-sidebar{
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1; /* Ensure the overlay is on top of other content */
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .mobile-sidebar{
    width: 200px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow: auto;
  }
  .mobile-sidebar{
    position: relative;
  }

  .close-sidebar{
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 10px;
    border: none;
    font-weight: bold;
 }
 .close-sidebar p{
  margin-top: 25px;
 }

 /* .sidebar-menu{
  margin-top: 4rem;
 } */
 .close-sidebar .flex{
  color: #F11A7B;
  border-bottom: none !important;
  align-items: center;
 }
  .mobile-sidebar .sidebar-menu-item{
    border-bottom: #FFDCEC 1px solid;
  }
  .mobile-sidebar .flex svg{
    margin-right: 10px;
  }
  
  .mobile-sidebar .sidebar-user-image{
    align-self: center;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-sidebar img[alt='sidebar-logo']{
    align-self: center;
    position: relative;
    bottom: 10px;
  }
  .mobile-sidebar img[alt='sidebar-logo']{
    height: 30px;
    margin-top: 2rem;
  }
  .mobile-sidebar  .sidebar-user-image p {
    font-size: 2rem;
    color: #F11A7B;
    font-weight: bold;
  }
  
  .mobile-sidebar img[alt='logo1']{
    height: 40px;
    margin-top: 2rem;
  }
  .dashboard-overlay{
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1; /* Ensure the overlay is on top of other content */
    cursor: pointer;
    display: flex;
  }
  .menu-subcategory{
    margin-left: 20px;
  }
  .menu-subcategory .flex{
    border-bottom: none !important;
  }
  
  .mobile-overlay-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
    color: #454545;
    position: absolute;
      top: 7rem;
      left: 16vw;
      right: 16vw;
      max-width: 280px;
      max-height: 100%;
      min-height: 0;
      margin: 10px;
      padding: 0rem 3rem;
  }
  
  .mobile-overlay-card{
    font-size: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }
  .mobile-overlay-card .selected-item{
    background-color: #F11A7B;
    color: white;
    padding: 12px 50%;
  }
  
  .card-open-components img[alt='star']{
    fill: #F11A7B;
  }
  
  .card-open-form input[type='checkbox']{
    appearance: none;
    border:  1px solid #F11A7B;
    background-color: white;
    position: relative;
    z-index: 0;
    top: 0px;
    left: 0px;
    padding: 5px;
}

  .card-open-form input[type='checkbox']::before{
    position: absolute;
    content: '\2713';
    font-size: 1rem;
    line-height: 12px;
    color: #00A82D;
    transform: scale(0);
    transition: 0.3s all ease-in-out;
    top: -4px;
    left: 1px;
    z-index: 1;
}
  
  .card-open-form input[type='checkbox']:checked::before{
    transform: scale(1);
    z-index: 1;
  }
  
  .card-open-form .flex input[type='text']{
    border: none;
    text-align: center;
  }
  .card-open-form .flex {
    align-items: center;
    justify-content: space-between
  }
  .dark-text,
  .card-heading{
    font-size: 1rem !important;
  }
  .card-open-components .rating p, .card-open-components .dark-text{
    font-size: 1rem;
  }
  .card-open-components .rating .dark-text{
    margin-left: -10px;
  }
  .card-open-components .product-card-left {
    align-self: flex-start;
  }
  
  .product-card-left>*{
    margin-left: 10px;
  }
  .product-card-left img[alt="product-image"] {
    margin-right: 0;
    max-width: 100%;
    min-width: 0;
  }
  .card-left-div{
    max-width: 100%;
    min-width: 0;
  }
  
  .card-left-div .dark-text{
    font-size: 1.2rem;
  }
  .card-left-div .stars img,
  .product-card-right .stars img{
    max-width: 100%;
    min-width: 0;
  }
  .card-left-div .stars svg{
    height: 20px;
    width: 20px;
  }
  .card-open-components .comments svg{
    margin-right: 10px;
  }

  .dashboard-navbar{
    position: static;
  }
  }